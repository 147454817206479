import React, { useEffect, useState } from "react";

import { Col, Row, Button, Form, FormGroup, Label, Input, CustomInput, InputGroup, InputGroupAddon, InputGroupText, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import { RiEyeCloseLine } from "react-icons/ri";
import { ImEye } from "react-icons/im";
import PasswordForce from "../../../Layout/AppMain/Components/PasswordForce";
import { ErrorToast } from "../../../Layout/AppMain/Components/Toast";
import validator from 'validator'
import { formatPassword, extractUrlParams } from 'nfutils';
import api from "../../../services/api";
import ReactInputMask from "react-input-mask";
import AuthenticationSliders from "../../../Layout/AppMain/Components/AuthenticationSliders";

const Register = ({ history, setLoading, isAuthenticated }) => {
  const [ validation, setValidation ] = useState({});
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [showPasswordForce, setShowPasswordForce] = useState(false);
  const defaultFormData = {
    name: '',
    email: '',
    password: '',
    phone: '',
    accept_terms: true,
    password_level: 0,
    service: ''
  }

  const customButtonSize = {
    fontSize: '1.3rem'
  }

  const [formData, setFormData] = useState({
    ...defaultFormData
  });

  const toggleViewPassword = () => {
    setViewPassword(!viewPassword);
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  const handleChange = (value, prop) => {
    let newData = {...formData}
    if (['password', 'confirm_password'].includes(prop)) {
      value = formatPassword(value)
    }

    if(prop === "phone"){
      value = value.replace(/[^0-9]+/g, '')
    }

    newData[prop] = value

    setFormData({
      ...newData
    })
  }

  const handleValidate = () => {
    let invalid = {}

    if (!formData.email || !validator.isEmail(formData.email)) {
      invalid.email = true
    }

    if (!formData.password) {
      invalid.password = true
    }

    if(formData.phone.length < 10){
      invalid.phone = true
    }

    if (Object.keys(invalid).length > 0) {
      setValidation(invalid)
      ErrorToast({ placeholder: 'Os campos marcados com * são obrigatórios' })
      return false
    }

    if (!formData.accept_terms) {
      invalid.accept_terms = true
      setValidation(invalid)
      ErrorToast({ placeholder: 'É necessário aceitar nossos termos e condições antes de prosseguir!' })
      return false
    }

    setValidation({})
    return true
  }

  const handleFormData = () => {
    const {phone: _, ...newData} = formData
    formData.phone.length === 10 ? newData.phone = formData.phone : newData.cellphone = formData.phone
    return {
      ...newData,
      force_change_password: false,
      email: formData.email.toLowerCase(),
      service: extractUrlParams(history.location.search).service ?? 'easy'
    }
  }

  const handleSubmit = async () => {
    if (!handleValidate()) {
      return
    }

    try {
      setLoading(true)
      await api.post('register', handleFormData())
      // Após o registro, realiza o login com os dados preenchidos na criação do cadastro
      api.get('../sanctum/csrf-cookie').then(r => {
        api.post('login', handleFormData()).then(response => {
          localStorage.setItem('@userInfo', JSON.stringify(response.data));
          setLoading(false);
          history.push('/obrigado');
        }).catch(e => {
          console.log(e);
          setLoading(false);
          let msg = e.errors ? e.errors.join("<br/>") : "Houve um problema com o login, verifique suas credenciais.";
          ErrorToast({ placeholder: msg });
        });
      }).catch(e => {
        console.log(e);
        setLoading(false);
        let msg = e.errors ? e.errors.join("<br/>") : "Ocorreu um erro interno ao tentar realizar o login, tente novamente mais tarde!";
        ErrorToast({ placeholder: msg });
      })

      setFormData({
        ...defaultFormData
      })
      history.push('/login')
    } catch (e) {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar realizar o registro, tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => setValidation({ ...validation, email: (!formData.email || !validator.isEmail(formData.email)) }), [formData.email])

  useEffect(() => setValidation({ ...validation, password: (!formData.password) }), [formData.password, formData.password_level])

  useEffect(() => setValidation({ ...validation, phone: (!formData.phone || formData.phone.length < 10) }), [formData.phone])

  useEffect(() => setValidation({ ...validation, accept_terms: !formData.accept_terms }), [formData.accept_terms])

  useEffect(() => {
    let params = extractUrlParams(history.location.search)
    if(params.email) {
      setFormData({
        ...defaultFormData,
        email: params.email
      })
    }
  }, []);

  // Adiciona o script do hotjar para gravar a tela
  useEffect(() => {
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:3298882,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated])

  return (
    <>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <AuthenticationSliders />
          <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
              <div className="row flex-nowrap">
                <div className="app-logo" />
                <div className="border-left app-logo-notas" />
              </div>
              <h4>
                <div>Bem Vindo,</div>
                <span>
                  Leva apenas alguns segundos para criar a sua conta
                </span>
              </h4>
              <div>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="email">
                        <span className="text-danger">*</span> E-mail
                      </Label>
                      <Input invalid={validation.email} type="email" name="email" id="email" placeholder="Seu melhor e-mail..." value={formData.email} onChange={({ target: { value } }) => handleChange(value, 'email')} autoComplete="off" onKeyUp={handleKeyUp} />
                      <FormFeedback>Informe um e-mail válido</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="name">Nome</Label>
                      <Input invalid={validation.name} type="text" name="text" id="name" placeholder="Seu nome e sobrenome..." value={formData.name} onChange={({ target: { value } }) => handleChange(value, 'name')} autoComplete="off" onKeyUp={handleKeyUp} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="password">
                        <span className="text-danger">*</span> Senha
                      </Label>
                      <InputGroup>
                        <Input invalid={validation.password} type={viewPassword ? 'text' : 'password'} name="password" id="password" placeholder="Senha de acesso..." value={formData.password} onChange={({ target: { value } }) => handleChange(value, 'password')} onBlur={() => setShowPasswordForce(false)} onFocus={() => setShowPasswordForce(true)} autoComplete="new-password" onKeyUp={handleKeyUp} />
                        <InputGroupAddon style={{cursor: 'pointer'}} addonType="append" onClick={toggleViewPassword}>
                            <InputGroupText>
                              <RiEyeCloseLine hidden={viewPassword}/>
                              <ImEye hidden={!viewPassword} />
                            </InputGroupText>
                        </InputGroupAddon>
                        <FormFeedback>Informe uma senha válida</FormFeedback>
                      </InputGroup>
                      {/* Tooltip para verificação de senha forte */}
                      {/* {
                        showPasswordForce &&
                        <>
                          <br />
                          <PasswordForce password={formData.password} onLevel={value => handleChange(value, 'password_level')} />
                        </>
                      } */}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                     <FormGroup>
                      <Label for="phone">
                        <span className="text-danger">*</span> Telefone fixo ou celular
                      </Label>
                      <InputGroup>
                        <Input
                        maskChar={''}
                        mask={formData.phone.length <= 10 ? '(99) 9999-9999?' : '(99) 99999-9999'}
                        formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
                        className="form-control"
                        invalid={validation.phone}
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Digite um celular ou telefone"
                        value={formData.phone}
                        onChange={({ target: { value } }) => handleChange(value, 'phone')}
                        autoComplete="phone"
                        onKeyUp={handleKeyUp}
                        tag={ReactInputMask}
                         />
                        <FormFeedback>Informe um telefone válido</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <span>
                    <InputGroup>
                      <CustomInput invalid={validation.accept_terms} type="checkbox" id="accept_terms" checked={formData.accept_terms} onChange={({ target: { checked } }) => handleChange(checked, 'accept_terms')} label="Aceitar nossos" />
                      &nbsp;
                      <a href="https://fuganholisistemas.com.br/termos" target="_blank" rel="noopener noreferrer">
                        Termos e Condições
                      </a>
                    </InputGroup>
                  </span>
                </FormGroup>
                <Row className="divider" />
                <div className="d-flex align-items-center">
                  <div className="ml-auto">
                    <Button type="button" color="primary" style={customButtonSize} size="lg" onClick={handleSubmit}>
                      Experimentar Grátis
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Register;
