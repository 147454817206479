import React, { Fragment } from 'react';
import { Button, Col } from 'reactstrap';

const FormFooter = ({showDelete, showBack, showSubmit, showStore, showStoreMulti, onDelete, onBack, onSubmit, onSubmitMulti, aditionalButtons, colorDelete, children, className, hidden, disableSubmit}) => {
  return (
    <Fragment>
      <br />
      <div className={`app-footer ${className}`} hidden={hidden}>
        <div className="app-footer__inner">
          <Col hidden={!children} className="notMobile">
            {children}
          </Col>
          <Col className="text-right">
            {aditionalButtons}
            {
              showDelete &&
              <Button id="btnDelete" className="mb-2 mr-2" onClick={onDelete} color={colorDelete} >Deletar</Button>
            }
            {
              showBack &&
              <Button id="btnBack" className="mb-2 mr-2" onClick={onBack} >Voltar</Button>
            }
            {
              showSubmit &&
              <Button id="btnSave" className="mb-2 mr-2" disabled={disableSubmit} color="primary" onClick={onSubmit} >Salvar</Button>
            }
            {
              showStore &&
              <Button id="btnRegister" className="mb-2 mr-2" color="primary" onClick={onSubmit} >Cadastrar</Button>
            }
            {
              showStoreMulti &&
              <Button id="btnRegisterAndRebuke" className="mb-2 mr-2" color="primary" onClick={onSubmitMulti} >Cadastrar e Repreencher</Button>
            }
          </Col>
        </div>
      </div>
    </Fragment>
  )
}

FormFooter.defaultProps = {
  showDelete: true,
  showBack: true,
  showSubmit: true,
  showStore: true,
  showStoreMulti: true,
  hidden: false,
  aditionalButtons: '',
  colorDelete: 'danger',
  className: ''
}

export default FormFooter;
