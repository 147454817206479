import React from "react";
import { Row, Col, Card, CardBody, CardHeader, CardFooter } from "reactstrap";

const PageContent = ({
  children,
  hidden,
  pageTitle,
  pageFooter,
  style,
  onClick,
  className
}) => {
  return (
    <>
      <Row hidden={hidden}>
        <Col md="12">
          <Card className={`main-card ${className}`} style={style} onClick={onClick}>
            {pageTitle !== '' &&
              <CardHeader>{pageTitle}</CardHeader>
            }
            <CardBody>
              {children}
            </CardBody>
            {pageFooter !== '' &&
              <CardFooter>{pageFooter}</CardFooter>
            }
          </Card>
        </Col>
      </Row>
    </>
    );
  }

PageContent.defaultProps = {
  hidden: false,
  pageTitle:'',
  pageFooter: '',
  className: '',
  style: {},
  onClick: () => {}
}

export default PageContent;
