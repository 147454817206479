import { useCallback, useState } from "react";
import DataTable from "../../../../../Layout/AppMain/Components/DataTable"
import PageContent from "../../../../../Layout/AppMain/PageContent"
import { formatDate, formatValue } from "nfutils";
import { Badge, Button, CustomInput } from "reactstrap";
import { PiArchiveBoxFill } from "react-icons/pi";
import { FaBan } from "react-icons/fa";
import { ErrorToast } from "../../../../../Layout/AppMain/Components/Toast";
import api from "../../../../../services/api";

function PurchasesNotes({ companyHash, setLoading, allowPurchases, setAllowPurchases, update, purchaseAuth }) {
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalItemsFilter, setTotalItemsFilter] = useState(0);
  const [formData, setFormData] = useState({
    company_hash: companyHash,
    purchase_auth: purchaseAuth || '',
  });

  const handleCheckbox = (purchase_id) => {
    if (allowPurchases.includes(purchase_id)) {
      setAllowPurchases(allowPurchases.filter(item => item !== purchase_id));
    } else {
      setAllowPurchases([...allowPurchases, purchase_id]);
    }
  }

  const getData = useCallback(({pageSize, pageIndex, filter, formData}) => {
    setLoading(true);
    api.get(
      `requests/${formData.company_hash}/purchase-invoices/${formData.purchase_auth}?${filter}`, {
        params: {
          limit: pageSize,
          page: pageIndex
        }
      }
    ).then(
      ({data: response}) => {
        let newItems = response.data.map((item) => {
          return {
            ...item,
            info_status:
              <>
                {item.hasPurchase && <Badge className={'mr-1'} style={{cursor: 'pointer'}} color="alternate" title="Pedido de compra gerado">P</Badge>}
                {item.hasInstallments && <Badge className={'mr-1'} style={{cursor: 'pointer'}} color="alternate" title="Conta a pagar gerada">C</Badge>}
                {item.products_received && <PiArchiveBoxFill className={'text-primary mr-1'} size={26} style={{cursor: 'pointer'}} title="Mercadoria Recebida"/>}
                {!item.activated_purchase_installments_generation && <FaBan className={'text-danger'} size={20} style={{cursor: 'pointer'}} title="Inativada a emissão de compra e contas para essa Nota"/>}
              </>,
            date: formatDate({value: item.date, location: 'Br', format: 'Date'}),
            vNF: formatValue({value: parseFloat(item.vNF), currency: 'BRL', maxFractionDigitis: 8}),
          }
        });
        setItems(newItems);
        setPageCount(Math.ceil(response.total / pageSize));
        setTotalItems(response.total);
        setTotalItemsFilter(response.totalFiltered);
      },
      error => {
        console.log(error);
      }
    )
    .catch(e => {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar buscar as informações de notas de compras'
      ErrorToast({ placeholder: msg })
    })
    .finally(() => setLoading(false));
  }, [update]);

  return (
    <PageContent>
      <h3 className="card-title">Notas de compra</h3>
      <br />
      <DataTable
        showFilter={false}
        columns={[
          {Header: "Número", accessor: "number", width: '5%'},
          {Header: "Data", accessor: "date", width: "8%"},
          {Header: "Natureza Operação", accessor: "operation_nature", width: '20%'},
          {Header: "Fornecedor", accessor: "provider.name", width: '25%'},
          {Header: "Valor", accessor: "vNF"},
          {Header: "Gerar contas a pagar", accessor: "checkbox",
            Cell: ({row}) => {
              return (
                <CustomInput
                  type="checkbox"
                  id={`checkbox${row.original.id}`}
                  checked={allowPurchases.includes(row.original.id)}
                  onChange={() => handleCheckbox(row.original.id)}
                />
              )
            },
          }
        ]}
        notRowCheckbox
        data={items}
        updateData={update}
        fetchData={getData}
        pageSize={10}
        pageCount={pageCount}
        totalReg={totalItems}
        totalRegFilter={totalItemsFilter}
        formData={formData}
      />
    </PageContent>
  )
}

export default PurchasesNotes
