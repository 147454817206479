import React, { Fragment } from "react";

import { FaRegBuilding } from "react-icons/fa";

const Subsidiary = ({ history }) => {
  return (
    <Fragment>
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div className="widget-content-left">
            <div className="icon-wrapper icon-wrapper-alt rounded-circle btn-opacity" style={{height: 44, width: 44, cursor: 'pointer'}} onClick={() => history.push('/filiais')}>
              <div className="icon-wrapper-bg bg-bgsecondary" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
              <FaRegBuilding style={{margin: "0 auto"}} color="var(--bgsecondary)" fontSize="20px" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Subsidiary;
