import React, { Fragment, useState } from 'react'
import { Badge, Card, CardBody, CardHeader, Col, Collapse, Input, Label, ListGroup, ListGroupItem, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap'
import PageContent from '../../../../../Layout/AppMain/PageContent'
import { FaCheckCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { formatDate, formatValue } from 'nfutils';
const ITEMS_PER_PAGE = 10;

function InstallmentsPayment({ installmentsPayment, setInstallmentsPayment, installmentsPaymentWithNote, setInstallmentsPaymentWithNote }) {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(installmentsPayment.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentItems = installmentsPayment.slice(startIndex, endIndex);

  const handleChangeMov = (movId, value) => {
    let newMovs = [...installmentsPayment];
    let newMov = newMovs.find((item) => item.id === movId);

    if (newMov) {
      newMov.public_customer_note = value;
    }

    let mov = installmentsPaymentWithNote.find((item) => item.id === movId);
    if (mov) {
      mov.public_customer_note = value;
    } else {
      installmentsPaymentWithNote.push({id: movId, public_customer_note: value});
    }

    setInstallmentsPayment(newMovs);
    setInstallmentsPaymentWithNote(installmentsPaymentWithNote);
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <PageContent>
      <h3 className="card-title">Contas a pagar</h3>
      <br />
      {
        installmentsPayment.length === 0 ?
          <div className='text-center'>
            <h5>Não há contas a pagar pendentes</h5>
          </div>
        :
          <div>
            <Table>
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>Data de vencimento</th>
                  <th>Valor</th>
                  <th>Informações sobre a conta a pagar</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={index}>
                    <td width="25%">{item.description}</td>
                    <td width="10%">{formatDate({ value: item.due_date, location: 'Br', format: 'Date' })}</td>
                    <td width="10%">{formatValue({ value: item.value, currency: 'BRL', maxFractionDigitis: 8 })}</td>
                    <td width="30%">
                      <Input
                        type='textarea'
                        maxLength={'100'}
                        placeholder='Insira informações sobre a conta a pagar'
                        value={item.public_customer_note}
                        onChange={({ target: { value } }) => handleChangeMov(item.id, value)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination style={{ float: 'right' }}>
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink first onClick={() => setCurrentPage(1)} />
              </PaginationItem>
              <PaginationItem disabled={currentPage === 1}>
                <PaginationLink previous onClick={handlePreviousPage} />
              </PaginationItem>
              {
                getPageNumbers().map(page => (
                  <PaginationItem key={page} active={page === currentPage}>
                    <PaginationLink onClick={() => setCurrentPage(page)}>
                      {page}
                    </PaginationLink>
                  </PaginationItem>
                ))
              }
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink next onClick={handleNextPage} />
              </PaginationItem>
              <PaginationItem disabled={currentPage === totalPages}>
                <PaginationLink last onClick={() => setCurrentPage(totalPages)} />
              </PaginationItem>
            </Pagination>
            <br />
          </div>
      }
    </PageContent>
  )
}

export default InstallmentsPayment
