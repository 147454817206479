import Slider from "react-slick";

import fugapoints from "../../../../assets/utils/images/authentication/FugaPoints.webp";
import bg1 from "../../../../assets/utils/images/authentication/bg1.webp";
import bg2 from "../../../../assets/utils/images/authentication/bg2.webp";
import bg3 from "../../../../assets/utils/images/authentication/bg3.webp";

import { Col } from "reactstrap";

const AuthenticationSliders = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 900,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true,
  };

  return (
    <>
      <Col lg="4" className="d-none d-lg-block">
        <div className="slider-light">
          <Slider {...settings}>
            <div className="h-100 d-flex justify-content-center align-items-center">
              <a href="https://indique.buzz/fuganholi" target="_blank" rel="noopener noreferrer">
                <div className="slide-img-bg slide-img"
                  style={{
                    backgroundImage: "url(" + fugapoints + ")",
                  }}
                >
                </div>
              </a>
            </div>
            <div className="h-100 d-flex justify-content-center align-items-center bg-woman">
              <div className="slide-img-bg"
                style={{
                  backgroundImage: "url(" + bg2 + ")",
                }}
              />
              <div className="slider-content">
                <h3>Nota Fiscal</h3>
                <p style={{fontSize: "24px"}}>
                  Emita notas fiscais de forma descomplicada
                  e ganhe agilidade e segurança.
                </p>
              </div>
            </div>
            <div className="h-100 d-flex justify-content-center align-items-center bg-man1">
              <div className="slide-img-bg"
                style={{
                  backgroundImage: "url(" + bg3 + ")",
                }}/>
              <div className="slider-content">
                <h3>Frente de Caixa</h3>
                <p style={{fontSize: "24px"}}>
                  Facilidade de uso permitindo que o operador
                  conclua a venda em poucos cliques.
                </p>
              </div>
            </div>
            <div className="h-100 d-flex justify-content-center align-items-center bg-man2">
              <div className="slide-img-bg opacity-6"
                style={{
                  backgroundImage: "url(" + bg1 + ")",
                }}/>
              <div className="slider-content">
                <h3>Estoque</h3>
                <p style={{fontSize: "24px"}}>
                  Controle e gerencie toda a movimentação do
                  estoque de produtos.
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </Col>
    </>
  )
}

export default AuthenticationSliders;
