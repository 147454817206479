import React, { Fragment, useState } from "react";

import { ImExit } from "react-icons/im";
import { Col, Container, Row } from "reactstrap";
import { getInfoUser, logout } from "../../../services/auth";

const Contador = ({ history }) => {
  const [user, setUser] = useState(getInfoUser());

  const contabilUrl =
    process.env.REACT_APP_ENV === "local"
      ? "https://app.nfcontador.com.br"
      : process.env.REACT_APP_ENV === "sandbox"
      ? "https://sandbox.fuganholi-contabil.com.br"
      : "https://app.fuganholi-contabil.com.br";

  const logoutAndRedirect = async () => {
    await logout();
    window.open(contabilUrl, "_self");
  };

  return (
    <Fragment>
      <div className="widget-content p-0">
        <div className="widget-content-wrapper">
          <div className="widget-content-right">
            <Container>
              <Row
                className="flex-nowrap rounded-pill ml-2 align-items-center px-2"
                style={{ background: "#D9DBF5" }}
              >
                <Col md={10} className="pr-0">
                  Você está acessando o cliente: <br />
                  <strong className="text-truncate">{user.company.name.length > 23 ? user.company.name.slice(0,22) + "..." : user.company.name}</strong>
                </Col>
                <Col md={2} className="pl-0">
                  <div
                    className="icon-wrapper icon-wrapper-alt rounded-circle"
                    style={{ height: 44, width: 44, cursor: "pointer" }}
                    onClick={() => logoutAndRedirect()}
                  >
                    <ImExit
                      style={{ margin: "0 auto", zIndex: 99999 }}
                      fontSize="20px"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Contador;
