import React, { Fragment, useState } from 'react'
import { Badge, Card, CardBody, CardHeader, Col, Collapse, Input, Label, ListGroup, ListGroupItem, Row } from 'reactstrap'
import PageContent from '../../../../../Layout/AppMain/PageContent'
import { FaCheckCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { formatDate, formatValue } from 'nfutils';

function Conciliations({ pendingMovs, setPendingMovs, movWithNote, setMovWithNote }) {
  const [activeBank, setActiveBank] = useState([]);

  const handleChangeMov = (bankIndex, movId, value) => {
    let newMovs = [...pendingMovs];
    let newMov = newMovs[bankIndex].data.find((item) => item.id === movId);

    if (newMov) {
      newMov.customer_note = value;
    }

    let mov = movWithNote.find((item) => item.id === movId);
    if (mov) {
      mov.customer_note = value;
    } else {
      movWithNote.push({id: movId, customer_note: value});
    }

    setPendingMovs(newMovs);
    setMovWithNote(movWithNote);
  }

  const handleActiveBanks = (index) => {
    if (activeBank.includes(index)) {
      setActiveBank(activeBank.filter((item) => item !== index));
    } else {
      setActiveBank([...activeBank, index]);
    }
  }
  return (
    <PageContent>
      <h3 className="card-title">Movimentações</h3>
      <br />
      {
        pendingMovs.length === 0 &&
        <div className='text-center'>
          <h5>Não há movimentações pendentes</h5>
        </div>
      }
      {
        pendingMovs.map((item, bankIndex) => {
          return (
            <Fragment key={bankIndex}>
              {bankIndex > 0 && <br />}
              <Card style={{border: '1px solid #cecece', borderRadius: '5px'}}>
                <CardHeader
                id={`collpase_bank_${item.account.id}`}
                style={{cursor: 'pointer', borderBottom: 'none'}}
                onClick={() => handleActiveBanks(bankIndex)}
                className='d-flex justify-content-between align-items-center'
                >
                  <h6 className="mb-0" style={{width: '100%'}}>
                    {item.account.fulldescription}
                    &nbsp;
                    &nbsp;
                    <Badge pill>
                      {item.data.length}
                    </Badge>
                    </h6>
                    <span style={{float: 'right'}}>
                      {
                        activeBank.includes(bankIndex) ?
                            <FaChevronUp />
                        :
                            <FaChevronDown />
                      }
                      </span>
                </CardHeader>
                {
                    activeBank.includes(bankIndex) ?
                    <Collapse isOpen={activeBank.includes(bankIndex)} toggler={`collpase_bank_${item.account.id}`}>
                      <hr />
                      <CardBody style={{padding: '2em 1em'}}>
                        <ListGroup className="todo-list-wrapper" flush>
                          {item.data.map((mov, index) => (
                            <Fragment key={index}>
                              <ListGroupItem id={`mov_${bankIndex}_${index}`}>
                                <div className={`todo-indicator ${mov.type === 1 ? "bg-success" : "bg-danger"}`} />
                                <Row className="widget-content p-0">
                                  <Col md='12' className="widget-content-wrapper">
                                    <FaCheckCircle size={24} className="text-success m-1" hidden={!mov.already_reconciled}/>
                                    <Col className="widget-content-left flex2 p-0" xs={12} md={6}>
                                      <div className="widget-content-left flex2">
                                        <Col md='12'>
                                          <h6 className="widget-heading">{mov.description}</h6>
                                        </Col>
                                        <Col className="widget-subheading">
                                          <Label className="mr-2">{`${formatDate({value: mov.date, location: 'Br', format: 'Date'})} | ${formatValue({value: mov.value, currency: 'BRL', maxFractionDigitis: 8})}`}</Label>
                                        </Col>
                                        <Row className='mb-3 d-block d-sm-none'>
                                          <Col xs={12}>
                                              <Input
                                                type='textarea'
                                                maxLength={'100'}
                                                placeholder='Insira informações sobre a movimentação'
                                                value={mov.customer_note}
                                                onChange={({target: {value}}) => handleChangeMov(bankIndex, mov.id, value)}
                                              />
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col xs={6} className='d-none d-sm-block'>
                                      <div className="widget-content-right">
                                        <Row className="text-center">
                                          <Label className="mr-2">Informações sobre movimentação</Label>
                                          <Input
                                          type='textarea'
                                          maxLength={'100'}
                                          placeholder='Insira informações sobre a movimentação'
                                          value={mov.customer_note}
                                          onChange={({target: {value}}) => handleChangeMov(bankIndex, mov.id, value)}/>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            </Fragment>
                          ))}
                        </ListGroup>
                      </CardBody>
                    </Collapse>
                  : null
                }
              </Card>
            </Fragment>
          )
        })
      }
      <br />
    </PageContent>
  )
}

export default Conciliations
