import React, { useEffect, useState } from 'react'
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import { Progress } from 'react-sweet-progress';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

const PasswordForce = ({ password, onLevel, target }) => {
  const [percent, setPercent] = useState(0);
  const [status, setStatus] = useState('veryLow');
  const [hasInPassword, setHasInPassword] = useState({})
  const [level, setLevel] = useState(0)

  const theme = {
    veryLow: {
      color: 'red',
      symbol: 'Fraca',
    },
    low: {
      color: 'orange',
      symbol: 'Regular'
    },
    strong: {
      color: '#2b2bf3',
      symbol: 'Forte'
    },
    veryStrong: {
      color: 'green',
      symbol: "Muito Forte",
    }
  }

  useEffect(() => {
    if (level < 2) {
      setPercent(25)
      setStatus('veryLow')
    } else if (level >= 2 && level < 6) {
      setPercent(50)
      setStatus('low')
    } else if (level >= 6 && level < 8) {
      setPercent(75)
      setStatus('strong')
    } else {
      setPercent(100)
      setStatus('veryStrong')
    }
  }, [level])

  useEffect(() => {
    let pointLength = 1
    let pointHas = 1
    let hasNumber = /[0-9]/.test(password)
    let hasLower = /[a-z]/.test(password)
    let hasUpper = /[A-Z]/.test(password)
    let hasSymbol = /[!@#$%&]/.test(password)
    let minLength = password.length >= 8

    if (password.length <= 3) {
      pointLength = 1
    } else if (password.length <= 7) {
      pointLength = 2
    } else if (password.length <= 12) {
      pointLength = 6
    } else {
      pointLength = 8
    }

    if (hasNumber && hasLower && hasUpper && hasSymbol) {
      pointHas = 8
    } else if (hasNumber && hasLower && hasUpper) {
      pointHas = 6
    } else if ((hasNumber && hasLower) || (hasNumber && hasUpper) || (hasLower && hasUpper)) {
      pointHas = 2
    }


    let result = (pointLength + pointHas)/2

    setLevel(result)
    onLevel(result)
    setHasInPassword({ hasNumber, hasLower, hasUpper, hasSymbol, minLength })
  }, [password])

  return <>
    <UncontrolledPopover placement="bottom" trigger="legacy" fade={false} isOpen={true} target={target} popperClassName="w-50 pr-5 pl-3">
      <PopoverBody>
        <Progress percent={percent} theme={theme} status={status} />
        <br />
        <span>Obrigatório</span>
        <br/>
        <span style={{ color: hasInPassword.minLength ? 'green' : 'red' }}>{hasInPassword.minLength ? <FaRegCheckCircle /> : <FaRegTimesCircle />} Tamanho (mín.: 8 caracteres)</span><br />
        <span style={{ color: hasInPassword.hasUpper ? 'green' : 'red' }}>{hasInPassword.hasUpper ? <FaRegCheckCircle /> : <FaRegTimesCircle />} Letra Maiúscula (A-Z)</span><br />
        <span style={{ color: hasInPassword.hasLower ? 'green' : 'red' }}>{hasInPassword.hasLower ? <FaRegCheckCircle /> : <FaRegTimesCircle />} Letra Minúscula (a-z)</span><br />
        <span style={{ color: hasInPassword.hasNumber ? 'green' : 'red' }}>{hasInPassword.hasNumber ? <FaRegCheckCircle /> : <FaRegTimesCircle />} Número (0-9)</span><br />
        <br/>
        <span>Opcional</span>
        <br/>
        <span style={{ color: hasInPassword.hasSymbol ? 'green' : 'red' }}>{hasInPassword.hasSymbol ? <FaRegCheckCircle /> : <FaRegTimesCircle />} Caracteres Especiais (!@#$%&)</span>
      </PopoverBody>
    </UncontrolledPopover>
  </>
}

PasswordForce.defaultProps = {
  target: 'password'
}

export default PasswordForce
