import React, { useEffect } from "react";

import api from "../../../services/api";

const LoginAccessSupport = ({ history, setLoading, isAuthenticated, match }) => {
  const { access_support_token } = match.params

  const consultToken = async () => {
    try {
      setLoading(true)
      api.get('../sanctum/csrf-cookie').then(_ => {
        api.post('access-support/validToken', {
          token: access_support_token
        })
        .then(response => {
          localStorage.setItem('@userInfo', JSON.stringify(response.data))
          window.location.assign('/')
          setLoading(false)
        })
        .catch(e => {
          console.log(e)
          setLoading(false)
          history.push('/login')
        })
      })
      .catch(e => {
        console.log(e)
        setLoading(false)
        history.push('/login')
      })
    } catch (e) {
      setLoading(false)
      console.log(e)
      history.push('/login')
    }
  }

  useEffect(() => {
    if (access_support_token) {
      consultToken()
    }
  }, [access_support_token])

  return (
    <>
    </>
  );
}

export default LoginAccessSupport;
