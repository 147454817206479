import { formatDate, formatValue } from 'nfutils';
import React, { useState } from 'react';
import { Input, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import PageContent from '../../../../../Layout/AppMain/PageContent';
const ITEMS_PER_PAGE = 10;

function InstallmentsReceive({ installmentsReceive, setInstallmentsReceive, installmentsReceiveWithNote, setInstallmentsReceiveWithNote }) {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(installmentsReceive.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentItems = installmentsReceive.slice(startIndex, endIndex);

  const handleChangeMov = (movId, value) => {
    let newMovs = [...installmentsReceive];
    let newMov = newMovs.find((item) => item.id === movId);

    if (newMov) {
      newMov.public_customer_note = value;
    }

    let mov = installmentsReceiveWithNote.find((item) => item.id === movId);
    if (mov) {
      mov.public_customer_note = value;
    } else {
      installmentsReceiveWithNote.push({id: movId, public_customer_note: value});
    }

    setInstallmentsReceive(newMovs);
    setInstallmentsReceiveWithNote(installmentsReceiveWithNote);
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <PageContent>
      <h3 className="card-title">Contas a receber</h3>
      <br />
      {
        installmentsReceive.length === 0 ?
        <div className='text-center'>
          <h5>Não há contas a receber pendentes</h5>
        </div>
        :
        <div>
          <Table>
            <thead>
              <tr>
                <th>Descrição</th>
                <th>Data de vencimento</th>
                <th>Valor</th>
                <th>Informações sobre a conta a receber</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td width="25%">{item.description}</td>
                  <td width="10%">{formatDate({ value: item.due_date, location: 'Br', format: 'Date' })}</td>
                  <td width="10%">{formatValue({ value: item.value, currency: 'BRL', maxFractionDigitis: 8 })}</td>
                  <td width="30%">
                    <Input
                      type='textarea'
                      maxLength={'100'}
                      placeholder='Insira informações sobre a conta a receber'
                      value={item.public_customer_note}
                      onChange={({ target: { value } }) => handleChangeMov(item.id, value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination style={{ float: 'right' }}>
            <PaginationItem disabled={currentPage === 1}>
              <PaginationLink first onClick={() => setCurrentPage(1)} />
            </PaginationItem>
            <PaginationItem disabled={currentPage === 1}>
              <PaginationLink previous onClick={handlePreviousPage} />
            </PaginationItem>
            {
              getPageNumbers().map(page => (
                <PaginationItem key={page} active={page === currentPage}>
                  <PaginationLink onClick={() => setCurrentPage(page)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))
            }
            <PaginationItem disabled={currentPage === totalPages}>
              <PaginationLink next onClick={handleNextPage} />
            </PaginationItem>
            <PaginationItem disabled={currentPage === totalPages}>
              <PaginationLink last onClick={() => setCurrentPage(totalPages)} />
            </PaginationItem>
          </Pagination>
          <br />
        </div>
      }
    </PageContent>
  )
}

export default InstallmentsReceive
