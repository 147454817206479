import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import validMenu from "../../services/validMenu";
import {
  SalesNav,
  ServicesNav,
  PurchasesNav,
  FinancialNav,
  ReportsNav
} from "./NavItems";
import { getInfoUser } from "../../services/auth";


const Nav = props => {
  const user = getInfoUser();
  let { enableMobileMenu, setEnableMobileMenu } = props;
  const [state, setState] = useState({
    menuSales: [],
    menuServices: [],
    menuPurchases: [],
    menuFinancial: [],
    menuReports: []
  })

  const toggleMobileSidebar = () => {
    setEnableMobileMenu(!enableMobileMenu);
  }

  const isPathActive = (path) => {
    return props.location.pathname.startsWith(path);
  }

  const loadMenu = async () => {
    let menu;
    let newState = {...state}

    let data = [
      {
        menu: SalesNav[0],
        permission: 'orders',
        state: 'menuSales'
      },
      {
        menu: ServicesNav[0],
        permission: 'services',
        state: 'menuServices'
      },
      {
        menu: PurchasesNav[0],
        permission: 'purchases',
        state: 'menuPurchases'
      },
      {
        menu: FinancialNav[0],
        permission: 'financial',
        state: 'menuFinancial'
      },
      {
        menu: ReportsNav[0],
        permission: 'reports',
        state: 'menuReports'
      },
    ]

    data.forEach(item => {
      if ((user.plan && typeof user.plan.permissions[item.permission] !== 'undefined') || (!user.plan && user.company.test)) {
        if (user.plan) {
          if (user.level === 1) {
            menu = validMenu(item.menu, user.plan.permissions[item.permission], user.plan.permissions[item.permission]);
          } else {
            menu = validMenu(item.menu, user.permissions, user.plan.permissions[item.permission]);
          }
        } else {
          menu = validMenu(item.menu, user.permissions, (user.company.test_permissions || user.permissions));
        }
      } else {
        menu = {content: []}
      }

      if (menu.content.length > 0) {
        newState = { ...newState, [item.state]: [menu]};
      }
    })

    setState(newState)
  }

  useEffect(() => {
    loadMenu();
  }, [])

  return (
    props.location.pathname !== "/bloqueado" && <Fragment>
      {Object.keys(state.menuSales).length > 0 &&
        <MetisMenu content={state.menuSales} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
      }
      {Object.keys(state.menuServices).length > 0 &&
        <MetisMenu content={state.menuServices} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
      }
      {Object.keys(state.menuPurchases).length > 0 &&
        <MetisMenu content={state.menuPurchases} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
      }
      {Object.keys(state.menuFinancial).length > 0 &&
        <MetisMenu content={state.menuFinancial} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
      }
      {Object.keys(state.menuReports).length > 0 &&
        <MetisMenu content={state.menuReports} onSelected={toggleMobileSidebar} activeLinkFromLocation
        className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
      }
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
