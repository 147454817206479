import React, { useEffect, useState } from "react";

import { Nav } from "reactstrap";
import NavItemMega from "./Components/NavItemMega";
import validMenu from "../../../../services/validMenu";

import { getInfoUser } from '../../../../services/auth';
import {
  SalesNav,
  ServicesNav,
  PurchasesNav,
  FinancialNav,
  ReportsNav
} from "../../../AppNav/NavItems";


const MegaMenu = ({location}) => {
  const user = getInfoUser();
  const [state, setState] = useState({
    menuSales: [],
    menuServices: [],
    menuPurchases: [],
    menuFinancial: [],
    menuReports: []
  });

  function sortNavItems(navItem) {
    if (navItem.content) {
      navItem.content.sort((a, b) => a.label.localeCompare(b.label));
      navItem.content = navItem.content.map(subItem => {
        if (subItem.content) {
          subItem.content.sort((a, b) => a.label.localeCompare(b.label));
        }
        return subItem;
      });
    }
    return navItem;
  }

  const loadMenu = async () => {
    let menu;
    let newState = {...state}

    let data = [
      {
        menu: sortNavItems(SalesNav[0]),
        permission: 'orders',
        state: 'menuSales'
      },
      {
        menu: sortNavItems(ServicesNav[0]),
        permission: 'services',
        state: 'menuServices'
      },
      {
        menu: sortNavItems(PurchasesNav[0]),
        permission: 'purchases',
        state: 'menuPurchases'
      },
      {
        menu: sortNavItems(FinancialNav[0]),
        permission: 'financial',
        state: 'menuFinancial'
      },
      {
        menu: sortNavItems(ReportsNav[0]),
        permission: 'reports',
        state: 'menuReports'
      },
    ]

    data.forEach(item => {
      if ((user.company && user.plan && typeof user.plan.permissions[item.permission] !== 'undefined') || (user.company && !user.plan && user.company.test)) {
        if (user.plan) {
          if (user.level === 1) {
            menu = validMenu(item.menu, user.plan.permissions[item.permission], user.plan.permissions[item.permission]);
          } else {
            menu = validMenu(item.menu, user.permissions, user.plan.permissions[item.permission]);
          }
        } else {
          menu = validMenu(item.menu, user.permissions, (user.company.test_permissions || user.permissions));
        }
      } else {
        menu = {content: []}
      }

      if (menu.content.length > 0) {
        newState = { ...newState, [item.state]: menu};
      }
    })

    setState(newState)
  }

  useEffect(() => {
    loadMenu();
  }, []);

  return (
    location.pathname !== "/bloqueado" && <>
      <Nav className="header-megamenu">
        {Object.keys(state.menuSales).length > 0 &&
          <NavItemMega data={state.menuSales} />
        }
        {Object.keys(state.menuServices).length > 0 &&
          <NavItemMega data={state.menuServices} />
        }
        {Object.keys(state.menuPurchases).length > 0 &&
          <NavItemMega data={state.menuPurchases} />
        }
        {Object.keys(state.menuFinancial).length > 0 &&
          <NavItemMega data={state.menuFinancial} />
        }
        {Object.keys(state.menuReports).length > 0 &&
          <NavItemMega data={state.menuReports} />
        }
      </Nav>
    </>
  );
}

export default MegaMenu;
