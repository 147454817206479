import React, { useEffect, useState } from "react";

import { RiEyeCloseLine } from 'react-icons/ri';
import { ImEye } from 'react-icons/im'

import { Col, Row, Button, FormGroup, Label, Input, InputGroupAddon, InputGroup, InputGroupText, CustomInput } from "reactstrap";
import { Link } from "react-router-dom";
import { ErrorToast } from "../../../Layout/AppMain/Components/Toast";
import AuthenticationSliders from "../../../Layout/AppMain/Components/AuthenticationSliders";

import api from "../../../services/api";
import { formatPassword } from 'nfutils';

const Login = ({ history, setLoading, isAuthenticated }) => {

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember_me: false
  });

  const [viewPassword, setViewPassword] = useState(false);

  const toggleViewPassword = () => {
    setViewPassword(!viewPassword);
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  }

  const handleChange = (value, prop) => {
    if (prop === 'password') {
      value = formatPassword(value);
    }

    setFormData({
      ...formData,
      [prop]: value
    })
  }

  const handleValidate = () => {
    if (!formData.email || !formData.password) {
      ErrorToast({ placeholder: "Preencha e-mail e senha para continuar!" });
      return false;
    }

    return true;
  }

  const handleFormData = () => {
    return {
      ...formData,
      email: formData.email.toLowerCase()
    }
  }

  const handleLogin = async () => {
    if (!handleValidate()) {
      return;
    }

    setLoading(true);
    api.get('../sanctum/csrf-cookie').then(r => {
      api.post('login', handleFormData()).then(response => {
        localStorage.setItem('@userInfo', JSON.stringify(response.data));
        setLoading(false);
        window.location.assign('/');
      }).catch(e => {
        console.log(e);
        setLoading(false);
        let msg = e.errors ? e.errors.join("<br/>") : "Houve um problema com o login, verifique suas credenciais.";
        ErrorToast({ placeholder: msg });
      });
    }).catch(e => {
      console.log(e);
      setLoading(false);
      let msg = e.errors ? e.errors.join("<br/>") : "Ocorreu um erro interno ao tentar realizar o login, tente novamente mais tarde!";
      ErrorToast({ placeholder: msg });
    })
  }

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated])

  return (
    <>
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <AuthenticationSliders />
          <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
              <div className="row flex-nowrap">
                <div className="app-logo" />
                <div className="border-left app-logo-notas" />
              </div>
              <h4>
                <div>Bem Vindo,</div>
                <span>
                  Preencha os dados abaixo para entrar no sistema!
                </span>
              </h4>
              <div>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label>E-mail</Label>
                      <Input type="email" value={formData.email} onChange={({ target: { value } }) => handleChange(value, 'email')} placeholder="Digite seu e-mail aqui..." autoComplete="username" onKeyUp={handleKeyUp} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Senha</Label>
                      <InputGroup>
                        <Input type={viewPassword ? 'text' : 'password'} value={formData.password} onChange={({ target: { value } }) => handleChange(value, 'password')} placeholder="Digite sua senha aqui..." autoComplete="current-password" onKeyUp={handleKeyUp}/>
                        <InputGroupAddon addonType="append" onClick={toggleViewPassword}>
                            <InputGroupText>
                              <RiEyeCloseLine hidden={viewPassword}/>
                              <ImEye hidden={!viewPassword} />
                            </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <CustomInput type="checkbox" id="remember_me" checked={formData.remember_me} onChange={({ target: { checked } }) => handleChange(checked, 'remember_me')} label="Me mantenha logado" />
                </FormGroup>
                <Row className="divider" />
                <h6 className="mt-3">
                  Ainda não possui uma conta?{" "}
                  <Link to="/register" className="text-primary">
                    Teste grátis
                  </Link>
                </h6>
                <div className="d-flex align-items-center">
                  <div className="ml-auto">
                    <Link id="forgot_password" className="btn-lg btn btn-link" to="/forgot-password">
                      Recuperar Senha
                    </Link>{" "}
                    <Button type="button" color="primary" size="lg" onClick={handleLogin}>
                      Login
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Login;
