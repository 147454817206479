import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter, Route, Redirect, Switch } from "react-router-dom"

import LoadingOverlay from "react-loading-overlay";
import { Loader } from "react-loaders";

import { ToastContainer } from "react-toastify";
import { dateNow } from 'nfutils';

import ResizeDetector from "react-resize-detector";

import { FormGroup, Label, Row, Col, Button, Modal, ModalHeader, ModalBody, Input, FormFeedback, Table, InputGroupAddon, InputGroupText, InputGroup, ModalFooter } from "reactstrap";
import ReactInputMask from "react-input-mask";
import { RiEyeCloseLine } from "react-icons/ri";
import { ImEye } from "react-icons/im";
import { ErrorToast, InfoToast, NotificationToast, SuccessToast } from "../../Layout/AppMain/Components/Toast";

import AppMain from "../../Layout/AppMain";
import LoginPage from "../../Pages/UserPages/Login";
import RegisterPage from "../../Pages/UserPages/Register";
import LoginAccessSupport from "../../Pages/UserPages/LoginAccessSupport";
import ForgotPasswordPage from "../../Pages/UserPages/ForgotPassword";
import RecoveryPasswordPage from "../../Pages/UserPages/RecoveryPassword";
import Subsidiary from "../../Pages/Subsidiary";

import Echo from 'laravel-echo';
import api from "../../services/api";
import { getInfoUser, logout } from "../../services/auth";
import { formatPassword } from 'nfutils';
import PasswordForce from '../../Layout/AppMain/Components/PasswordForce/index';
import ThanksPage from "../UserPages/Thanks";
import LoginContador from "../UserPages/LoginContador";
import GuestAccess from "../UserPages/GuestAccess";
window.Pusher = require('pusher-js');

const PrivateRoute = ({ component: Component, setLoading, setLoadType, isBlocked, setIsBlocked, isAuthenticated, setIsAuthenticated, useHere, width, notifications, updateUser, setUpdateUser, showAlertTrialPeriod, isTrialPeriod, testTerm, toggleAlertTrialPeriod, ...rest }) => (
  <LoadingOverlay tag="div" active={isBlocked}
  styles={{
    overlay: (base) => ({
      ...base,
      background: "#f1f4f6",
      color: "#000",
      position: 'fixed',
      zIndex: '99999999999999'
    }),
  }}
  spinner={false}
  text={<Row style={{textAlign: 'right'}}>
    <Col md={12}>
      <FormGroup>
        <Label>Acesso ativo em outra janela, clique em "Usar aqui" para utilizá-lo nesta janela ou clique em "Desconectar" para voltar a página de login</Label>
      </FormGroup>
    </Col>
    <Col md={12}>
      <Button style={{margin: '10px', fontSize: '15px'}} onClick={async () => {
        setIsBlocked(false);
        setLoading(true);
        await logout();
        setLoading(false);
      }} color="danger" outline={true} >Desconectar</Button>
      <Button style={{margin: '10px', fontSize: '15px'}} onClick={useHere} color="primary" >Usar aqui</Button>
    </Col>
  </Row>}
  >
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component
            {...props}
            width={width}
            setLoading={(isActive) => setLoading(isActive)}
            setLoadType={(typeLoad) => setLoadType(typeLoad)}
            setIsAuthenticated={(auth) => setIsAuthenticated(auth)}
            notifications={notifications}
            updateUser={updateUser}
            setUpdateUser={setUpdateUser}
            showAlertTrialPeriod={showAlertTrialPeriod}
            isTrialPeriod={isTrialPeriod}
            testTerm={testTerm}
            toggleAlertTrialPeriod={(toggle) => toggleAlertTrialPeriod(toggle)}
          />
        ) : (
          isAuthenticated !== null &&
          <Redirect to="/login" />
        )
      }
    />
  </LoadingOverlay>
);

const Main = (props) => {
  LoadingOverlay.propTypes = undefined;
  const [loadActive, setLoadActive] = useState(false);
  const [loaderType, setLoadType] = useState('pacman');
  const [updateUser, setUpdateUser] = useState(false);
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const userDataRef = useRef([]);
  userDataRef.current = users;
  const [isBlocked, setIsBlocked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [modalPassword, setModalPassword] = useState(false);
  const [showAlertTrialPeriod, setshowAlertTrialPeriod] = useState(false);
  const [isTrialPeriod, setisTrialPeriod] = useState(false);
  const [validation, setValidation] = useState({});
  const defaultFormData = {
    old_password: '',
    password: '',
    confirm_password: '',
    level: 0
  }
  const [formData, setFormData] = useState({
    ...defaultFormData
  });
  const [testTerm, setTestTerm] = useState();
  const [showPasswordForce, setShowPasswordForce] = useState(false);
  const [viewOldPassword, setViewOldPassword] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);

  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
  } = props;

  const useHere = () => {
    let allUsers = [...userDataRef.current];
      for (let i in allUsers) {
        if (allUsers[i].token == user.token) {
          allUsers[i].blocked = false;
        } else {
          allUsers[i].blocked = true;
        }
      }
      setIsBlocked(false);
      setUsers([...allUsers]);

      window.Echo.join(`user_session.${user.id}`).whisper('useHere', user)
  }

  const handleClickNotification = (notification) => {
    let url = notification.url ? notification.url : `/#/notificacoes/visualiza/${notification.id}`
    window.open(url)

    if (!notification.date_viewed) {
      api.post(`notifications/${notification.id}/viewed`)
      .then(response => {
        setUser({
          ...user,
          notifications: user.notifications.map(not => {
            if (not.id === notification.id) {
              return {
                ...response.data
              }
            }

            return not
          })
        })
      })
      .catch(e => {
        console.log(e)
      })
    }
  }

  const handleNotify = async (n) => {
    if (document.visibilityState === 'visible') {
      NotificationToast({ title: n.title, message: n.message, onClick: () => handleClickNotification(n) })
    }

    if (Notification.permission === 'granted' && document.visibilityState === 'hidden') {
      const notification = new Notification(n.title, {
        body: n.message
      })

      notification.onclick = () => handleClickNotification(n)
    }
  }

  const requestPermissionNotification = async () => {
    if (!Notification) {
      console.log('Esse browser não suporta notificações desktop');
    } else {
      if (!['denied', 'granted'].includes(Notification.permission)) {
        // Pede ao usuário para utilizar a Notificação Desktop
        await Notification.requestPermission();
      }
    }
  }

  const handleChangePassword = (value, prop) => {
    setFormData({
      ...formData,
      [prop]: formatPassword(value)
    })
  }

  const toggleViewOldPassword = () => {
    setViewOldPassword(!viewOldPassword);
  }

  const toggleViewPassword = () => {
    setViewPassword(!viewPassword);
  }

  const toggleViewConfirmPassword = () => {
    setViewConfirmPassword(!viewConfirmPassword);
  }

  const handleValidatePassword = () => {
    let invalid = {}
    if (!formData.old_password) {
      invalid.old_password = true
    }
    if (!formData.password || formData.level < 1) {
      invalid.password = true
    }
    if (formData.password && (!formData.confirm_password || formData.confirm_password !== formData.password)) {
      invalid.confirm_password = true
    }

    if (Object.keys(invalid).length > 0) {
      setValidation(invalid)
      ErrorToast({ placeholder: 'Os campos marcados com * são obrigatórios' })
      return false
    }

    setValidation({})
    return true
  }

  const handleFormPassword = () => {
    return {
      old_password: formData.old_password,
      password: formData.password,
      confirm_password: formData.confirm_password,
      edit_password: 1
    }
  }

  const handleUpdatePassword = async () => {
    if (!handleValidatePassword()) {
      return
    }

    try {
      setLoadActive(true)
      await api.put(`users/${user.id}`, handleFormPassword())
      SuccessToast({ placeholder: 'Senha alterada com sucesso!' })
      setModalPassword(false)
    } catch (e) {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar atualizar sua senha, tente novamente mais tarde'
      ErrorToast({ placeholder: msg })
    } finally {
      setLoadActive(false)
    }
  }

  const toggleAlertTrialPeriod = () => {
    sessionStorage.setItem('@alertTrialPeriod', false);
    setshowAlertTrialPeriod(false);
  }

  useEffect(() => {
    const getData = async () => {
      try {
        let response = await api.get('users/me');
        let data = {
          ...getInfoUser(),
          ...response.data
        }
        localStorage.removeItem('@userInfo');
        localStorage.setItem('@userInfo', JSON.stringify(data));
        setIsAuthenticated(true);
        setUser(data);
        if (!data.company_id) {
          props.history.push('/filiais')
        }
      } catch (e) {
        console.log(e);
        setIsAuthenticated(false);
      }
    }

    getData();
  }, [updateUser]);

  useEffect(() => {
    if (user.id === undefined) {
      return;
    }
    const options = {
      broadcaster: 'pusher',
      key: '9cbababdd85a841674e2',
      forceTLS: true,
      wsHost: process.env.REACT_APP_WBSOCKET_HOST || 'api.fuganholi-easy.com.br',
      wsPort: process.env.REACT_APP_WEBSOCKET_PORT || 6001,
      wssPort: process.env.REACT_APP_WEBSOCKET_PORT || 6001,
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, callback) => {
            api.interceptors.request.use(async config => {
              config.headers['X-Socket-ID'] = socketId;
              return config;
            });
            api.post('broadcasting/auth', {
                socket_id: socketId,
                channel_name: channel.name,
                token: user.token
            }).then(response => {
                callback(false, response.data);
            })
            .catch(error => {
                callback(true, error);
            })
          }
        }
      }
    }
    window.Echo = new Echo(options);
    window.Echo.join(`user_session.${user.id}`)
    .here(users => {
      setUsers(users);
    })
    .joining(u => {
      let allUsers = [...userDataRef.current];
      allUsers = allUsers.filter(u2 => u2.token !== u.id);
      for (let i in allUsers) {
        allUsers[i].blocked = true;
      }
      allUsers.push({...u, blocked: false});
      for (let i in allUsers) {
        if (allUsers[i].token == user.token) {
          setIsBlocked(allUsers[i].blocked);
        }
      }
      setUsers([...allUsers]);
    })
    .leaving(u => {
      let allUsers = [...userDataRef.current];
      allUsers = allUsers.filter(u2 => u2.token !== u.token);
      setUsers(allUsers)
    })
    .listenForWhisper('useHere', u => {
      let allUsers = [...userDataRef.current];
      allUsers = allUsers.filter(u2 => u2.token !== u.token);
      for (let i in allUsers) {
        allUsers[i].blocked = true;
      }
      allUsers.push({...u, blocked: false});
      for (let i in allUsers) {
        if (allUsers[i].token == user.token) {
          setIsBlocked(allUsers[i].blocked);
        }
      }
      setUsers([...allUsers]);
    })
    .listen('NewNotification', r => {
      api.get('users/me')
      .then(response => {
        let data = {
          ...getInfoUser(),
          ...response.data
        }

        localStorage.removeItem('@userInfo');
        localStorage.setItem('@userInfo', JSON.stringify(data));
        setUser(data);
        handleNotify(r.notification)
      })
      .catch(e => {
        console.log(e)
      })
      api.post(`notifications/${r.notification.id}/received`)
      .catch(e => {
        console.log(e)
      })
    })
    .listen('AccessBlock', r => {
      api.get('users/me')
      .then(response => {
        let data = {
          ...getInfoUser(),
          ...response.data
        }

        localStorage.removeItem('@userInfo');
        localStorage.setItem('@userInfo', JSON.stringify(data));
        props.history.push('/bloqueado');
        setUser(data);
      })
      .catch(e => {
        console.log(e)
      })
    });

    if (user.type === 2) {
      window.Echo.join(`user_support_session.${user.company_id}.${user.id}`)
      .listen('DisconnectAccessSupport', async r => {
        if (r.access.company_id === user.company_id && r.user.id === user.id) {
          await logout();
        }
      })
    }
  }, [user.id, user.token]);

  useEffect(() => {
    if (isAuthenticated) {
      requestPermissionNotification()
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (user && user.plan && user.plan.nfpanel_contract_id === 0 && user.force_change_password && (![0, 2].includes(user.plan.status) || user.plan.filial) && user.company && (user.company.test < dateNow())) {
      setModalPassword(true)
      return
    }

    setModalPassword(false)
  }, [user.force_change_password, user.plan])

  useEffect(() => {
    if (user && user.company && !user.plan && user.company.test_term !== null && user.company.test_term <= 5) {
      if (sessionStorage.getItem('@alertTrialPeriod') !== 'false') {
        let term;
        if (user.company.test_term === 1) {
          term = 'amanhã'
        } else if (user.company.test_term === 0) {
          term = 'hoje'
        } else {
          term = `em ${user.company.test_term} dias`
        }
        setTestTerm(term);
        setshowAlertTrialPeriod(true);
      }
      setisTrialPeriod(true);
    }
  }, [user.company])

  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <>
          <LoadingOverlay styles={{
            overlay: (base) => ({
              ...base,
              position: 'fixed',
              zIndex: '999999999999'
            })
          }} tag="div" active={loadActive}
            spinner={<Loader active type={loaderType} />}>
            <div
              className={cx(
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader },
                { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                { "fixed-footer": enableFixedFooter },
                { "closed-sidebar": enableClosedSidebar || width < 1250 },
                { "closed-sidebar-mobile": true,},
                { "sidebar-mobile-open": enableMobileMenu },
                { "body-tabs-shadow-btn": enablePageTabsAlt }
              )}>
                <Switch>
                  <Route exact path="/login/:access_support_token" render={(props) => (
                    <LoginAccessSupport
                      {...props}
                      setLoading={(isActive) => setLoadActive(isActive)}
                      setLoadType={(typeLoad) => setLoadType(typeLoad)}
                      isAuthenticated={isAuthenticated}
                    />
                  )}/>
                  <Route exact path="/login-contador/:access_contador_token" render={(props) => (
                    <LoginContador
                      {...props}
                      setLoading={(isActive) => setLoadActive(isActive)}
                      setLoadType={(typeLoad) => setLoadType(typeLoad)}
                      isAuthenticated={isAuthenticated}
                    />
                  )}/>
                  <Route exact path="/login" render={(props) => (
                    <LoginPage
                      {...props}
                      setLoading={(isActive) => setLoadActive(isActive)}
                      setLoadType={(typeLoad) => setLoadType(typeLoad)}
                      isAuthenticated={isAuthenticated}
                    />
                  )}/>
                  <Route exact path="/forgot-password" render={(props) =>
                    <ForgotPasswordPage
                      {...props}
                      setLoading={(isActive) => setLoadActive(isActive)}
                      setLoadType={(typeLoad) => setLoadType(typeLoad)}
                      isAuthenticated={isAuthenticated} />
                  } />
                  <Route exact path="/recovery-password/:user_id/:token" render={(props) =>
                    <RecoveryPasswordPage
                      {...props}
                      setLoading={(isActive) => setLoadActive(isActive)}
                      setLoadType={(typeLoad) => setLoadType(typeLoad)}
                      isAuthenticated={isAuthenticated} />
                  } />
                  <Route exact path="/register" render={(props) => (
                    <RegisterPage
                      {...props}
                      setLoading={(isActive) => setLoadActive(isActive)}
                      setLoadType={(typeLoad) => setLoadType(typeLoad)}
                      isAuthenticated={isAuthenticated}
                    />
                  )}/>
                 <Route exact path="/obrigado" render={(props) => (
                    <ThanksPage
                      {...props}
                      setLoading={(isActive) => setLoadActive(isActive)}
                      setLoadType={(typeLoad) => setLoadType(typeLoad)}
                      isAuthenticated={isAuthenticated}
                    />
                  )}/>
                  <Route exact path="/pendencias/:company_hash" render={(props) => (
                    <GuestAccess
                      {...props}
                      setLoading={(isActive) => setLoadActive(isActive)}
                      setLoadType={(typeLoad) => setLoadType(typeLoad)}
                    />
                  )}/>
                  <Route exact path="/filiais" render={(props) =>
                    isAuthenticated ? (
                      <Subsidiary
                      {...props}
                      setLoading={(isActive) => setLoadActive(isActive)}
                      setLoadType={(typeLoad) => setLoadType(typeLoad)}
                      isAuthenticated={isAuthenticated} />
                    ) : (
                      isAuthenticated !== null &&
                      <Redirect to="/login" />
                    )
                  } />
                  <PrivateRoute path="/"
                    component={AppMain}
                    width={width}
                    setLoading={(isActive) => setLoadActive(isActive)}
                    setLoadType={(typeLoad) => setLoadType(typeLoad)}
                    setIsBlocked={(blocked) => setIsBlocked(blocked)}
                    isBlocked={isBlocked}
                    showAlertTrialPeriod={showAlertTrialPeriod}
                    isTrialPeriod={isTrialPeriod}
                    testTerm={testTerm}
                    toggleAlertTrialPeriod={(toggle) => toggleAlertTrialPeriod(toggle)}
                    useHere={useHere}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={(auth) => setIsAuthenticated(auth)}
                    notifications={user.notifications}
                    updateUser={updateUser}
                    setUpdateUser={setUpdateUser}
                  />
                </Switch>
                <Modal size="lg" isOpen={modalPassword} toggle={() => {}}>
                  <ModalHeader>Alterar senha</ModalHeader>
                  <ModalBody>
                      <Row>
                        <Col>
                          <h5 className="card-title">Para manter suas informações mais seguras, precisamos que altere sua senha utilizando os campos abaixo:</h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} sm={12}>
                          <FormGroup>
                            <Label for="old_password">Senha Atual <b>*</b></Label>
                            <InputGroup>
                              <Input type={viewOldPassword ? 'text' : 'password'} invalid={validation.old_password} id="old_password" value={formData.old_password} onChange={({ target: { value } }) => handleChangePassword(value, 'old_password')} placeholder="Informe a senha atual..." />
                              <InputGroupAddon style={{cursor: 'pointer'}} addonType="append" onClick={toggleViewOldPassword}>
                                  <InputGroupText>
                                    <RiEyeCloseLine hidden={viewOldPassword}/>
                                    <ImEye hidden={!viewOldPassword} />
                                  </InputGroupText>
                              </InputGroupAddon>
                              <FormFeedback>Informe a sua senha atual do sistema</FormFeedback>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md={4} sm={12}>
                          <FormGroup>
                            <Label for="password">Nova Senha <b>*</b></Label>
                            <InputGroup>
                              <Input type={viewPassword ? 'text' : 'password'} invalid={validation.password} id="password" value={formData.password} onChange={({ target: { value } }) => handleChangePassword(value, 'password')} onBlur={() => setShowPasswordForce(false)} onFocus={() => setShowPasswordForce(true)} placeholder="Informe a nova senha..." />
                              <InputGroupAddon style={{cursor: 'pointer'}} addonType="append" onClick={toggleViewPassword}>
                                  <InputGroupText>
                                    <RiEyeCloseLine hidden={viewPassword}/>
                                    <ImEye hidden={!viewPassword} />
                                  </InputGroupText>
                              </InputGroupAddon>
                              <FormFeedback>Informe uma senha válida</FormFeedback>
                            </InputGroup>
                            {
                              showPasswordForce &&
                              <>
                                <br />
                                <PasswordForce password={formData.password} onLevel={value => setFormData({
                                  ...formData,
                                  level: value
                                })} />
                              </>
                            }
                          </FormGroup>
                        </Col>
                        <Col md={4} sm={12}>
                          <FormGroup>
                            <Label for="confirm_password">Confirmar Senha <b>*</b></Label>
                            <InputGroup>
                              <Input type={viewConfirmPassword ? 'text' : 'password'} invalid={validation.confirm_password} id="confirm_password" value={formData.confirm_password} onChange={({ target: { value } }) => handleChangePassword(value, 'confirm_password')} placeholder="Confirme a nova senha..." />
                              <InputGroupAddon style={{cursor: 'pointer'}} addonType="append" onClick={toggleViewConfirmPassword}>
                                  <InputGroupText>
                                    <RiEyeCloseLine hidden={viewConfirmPassword}/>
                                    <ImEye hidden={!viewConfirmPassword} />
                                  </InputGroupText>
                              </InputGroupAddon>
                              <FormFeedback>Informe a mesma senha digitada no campo Nova Senha</FormFeedback>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Row style={{margin: 0, width: '100%'}}>
                      <Col md="12">
                        <Button style={{float: 'right'}} color="primary" onClick={handleUpdatePassword}>
                          Alterar Senha
                        </Button>
                      </Col>
                    </Row>
                  </ModalFooter>
                </Modal>
            </div>
          </LoadingOverlay>
          <ToastContainer />
        </>
      )}
    />
  );
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));

