import React, { useEffect, useState } from 'react';
import { FaRegBuilding } from 'react-icons/fa';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { getInfoUser } from '../../services/auth';
import { formatCpfCnpj } from 'nfutils';
import './style.css';
import { ErrorToast } from '../../Layout/AppMain/Components/Toast';

import api from '../../services/api';

const Subsidiary = ({ history, setLoading }) => {
  const user = getInfoUser();
  const [ companyId, setCompanyId ] = useState(null);

  const handleSelectCompany = async (company_id) => {
    setCompanyId(company_id)

    if (company_id === user.company_id) {
      history.push('/')
      return
    }

    try {
      setLoading(true)
      await api.post('users/companies/change', {
        company_id: company_id
      })

      window.location.assign('/')
    } catch (e) {
      console.log(e)
      let msg = e.errors ? e.errors.join('<br />') : 'Ocorreu um erro interno ao tentar acessar a empresa, tente novamente mais tarde';
      ErrorToast({ placeholder: msg })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setCompanyId(user.company_id)
  }, [user.company_id])

  return (
    <div style={{ padding: 20 }}>
      <Row className="text-center">
        <Col>
          <h1>Alternar Empresa</h1>
          <h2>Escolha abaixo a empresa que deseja acessar!</h2>
        </Col>
      </Row>
      <Row style={{ padding: 20 }}>
        {
          (user && user.subsidiaries) && user.subsidiaries.map((subsidiary, i) => {
            return (
              <Col key={i} md={4} sm={6} xm={12}>
                <Card className={`cardCompany ${companyId === subsidiary.company_id ? 'selected' : ''}`} onClick={() => handleSelectCompany(subsidiary.company_id)}>
                  <CardBody className="text-center">
                    <FaRegBuilding style={{margin: "0 auto", marginBottom: 10}} fontSize="40px" />
                    <div>
                      <p><b>Razão Social: </b>{subsidiary.company.name || 'Empresa em Teste'}</p>
                      <p><b>CNPJ: </b>{subsidiary.company.cpfcnpj ? formatCpfCnpj({ value: subsidiary.company.cpfcnpj, type: 'CNPJ' }) : 'Sem informação'}</p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )
          })
        }
      </Row>
    </div>
  )
}

export default Subsidiary;
