import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

const Index =  lazy(() =>  import("./Index"));

const NFeDisablement = (props) => {
  return (
    <Suspense>
      <Switch>
        <Route exact path={`${props.match.url}/`} render={({match}) => <Index {...props} match={match} />}/>
      </Switch>
    </Suspense>
  )
};

export default NFeDisablement;
