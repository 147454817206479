import React from 'react';
import { toast, Bounce, Flip } from 'react-toastify';
import { Button } from 'reactstrap';

export const SuccessToast = ({ placeholder, options }) =>{
  var defaultOptions = {
    transition: Bounce,
    closeButton: true,
    autoClose: 5000,
    position: "top-right",
    theme: 'colored'
  }

  defaultOptions = {
    ...defaultOptions,
    ...options,
    type: 'success'
  }
  toast(
    () => <div dangerouslySetInnerHTML={{__html: placeholder}} />,
    defaultOptions
  );
}

export const ErrorToast = ({ placeholder, options }) => {
  var defaultOptions = {
    transition: Bounce,
    closeButton: true,
    autoClose: 7000,
    position: "top-right",
    theme: 'colored'
  }

  defaultOptions = {
    ...defaultOptions,
    ...options,
    type: 'error'
  }
  toast(
    () => <div dangerouslySetInnerHTML={{__html: placeholder}} />,
    defaultOptions
  );
}

export const InfoToast = ({ placeholder, options }) => {
  var defaultOptions = {
    transition: Bounce,
    closeButton: true,
    autoClose: 7000,
    position: "top-right",
    theme: 'colored'
  }

  defaultOptions = {
    ...defaultOptions,
    ...options,
    type: 'info'
  }
  toast(
    () => <div dangerouslySetInnerHTML={{__html: placeholder}} />,
    defaultOptions
  );
}

export const WarningToast = ({ placeholder, options }) => {
  var defaultOptions = {
    transition: Bounce,
    closeButton: true,
    autoClose: 7000,
    position: "top-right",
    theme: 'colored'
  }

  defaultOptions = {
    ...defaultOptions,
    ...options,
    type: 'warning'
  }

  toast(
    () => <div dangerouslySetInnerHTML={{__html: placeholder}} />,
    defaultOptions
  );
}

export const ConfirmToast = ({ placeholder = 'Deseja mesmo deletar o registro?', options = {}, onClick = () => {} }) => {
  var defaultOptions = {
    transition: Flip,
    closeButton: true,
    autoClose: 7000,
    position: "top-right",
    theme: 'colored',
    type: "warning",
    theme: 'colored'
  }

  defaultOptions = {
    ...defaultOptions,
    ...options
  }

  var classButton = 'btn btn-warning';

  if (defaultOptions.type === 'success') {
    classButton = 'btn btn-success';
  } else if (defaultOptions.type === 'error') {
    classButton = 'btn btn-danger';
  } else if (defaultOptions.type === 'info') {
    classButton = 'btn btn-info';
  }

  toast(
    () => <><div dangerouslySetInnerHTML={{__html: placeholder}} /><br /><Button type="button" onClick={onClick} className={classButton} style={{border: "1px solid white", color: "white"}}>Sim</Button></>,
    defaultOptions
  );
}

export const AlterToast = ({ placeholder = 'Deseja mesmo alterar o registro?', options = {}, onSave = () => {}, onDelete = () => {} }) => {
  var defaultOptions = {
    transition: Flip,
    closeButton: true,
    autoClose: 7000,
    position: "top-right",
    theme: 'colored',
    type: "warning",
    ...options
  }

  toast(
    (<><p>{placeholder}</p><Button type="button" onClick={onSave} color="success" >Sim</Button>  <Button type="button" onClick={onDelete} color="danger" >Não</Button></>),
    defaultOptions
  );
}

export const NotificationToast = ({ title, message, options = {}, onClick = () => {} }) => {
  if (message) {
    var defaultOptions = {
      transition: Bounce,
      closeButton: true,
      autoClose: 10000,
      position: "top-right",
      theme: 'colored',
      type: "default",
      pauseOnHover: true,
      draggable: true,
      hideProgressBar: true
    }

    defaultOptions = {
      ...defaultOptions,
      ...options,
      onClick
    }

    toast(
      () => <>
        {
          title &&
          <>
            <p><b>{title}</b></p>
            <br />
          </>
        }
        {message}
      </>,
      defaultOptions
    );
  }
}
