import React, { Fragment, useState } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import { ErrorToast } from '../../AppMain/Components/Toast'

import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Col,
  Row,
  Button,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
} from "reactstrap";

import background from "../../../assets/utils/images/dropdown-header/abstract6.jpg";

import { FaTicketAlt, FaHeadset, FaRegUser, FaRegLightbulb } from "react-icons/fa";

import { getInfoUser, logout } from "../../../services/auth";

const UserBox = ({ history, setLoading, location }) => {
  const user = getInfoUser();
  const [toggleDropDown, setToggleDropDown] = useState(false);

  const handleToggleDropDown = () => {
    setToggleDropDown(!toggleDropDown);
  }

  const handleCheckPlanPermission = permission => {
    if (location.pathname !== "/bloqueado") {
      if (user.permissions.includes('company_profile') && !user.plan && (!user.company || !user.company.test_permissions || user.company.test_permissions.includes('company_profile'))) {
        return true;
      } else if (user.plan && user.plan.permissions.others && user.plan.permissions.others.includes('company_profile')) {
        return true;
      }
    }

    return false;
  }
  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left ml-3 header-user-info">
              <div className="widget-heading">{user.name}</div>
              <div className="widget-subheading">{user.email}</div>
            </div>
            <div className="widget-content-left">
              <UncontrolledButtonDropdown id="btnUserBox" toggle={handleToggleDropDown} isOpen={toggleDropDown}>
                <DropdownToggle onClick={handleToggleDropDown} className="p-0 ml-2 mr-2" color="link">
                  <div className="icon-wrapper icon-wrapper-alt rounded-circle" style={{height: 44, width: 44}}>
                    <div className={`icon-wrapper-bg ${(!user.company || !user.company.logo_url) ? 'bg-primary' : null}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
                    {
                      (user.company && user.company.logo_url) &&
                      <img src={user.company.logo_url} style={{maxWidth: 44, maxHeight: 44, margin: '0 auto'}} alt="Logo da Empresa" />
                    }
                    {
                      (!user.company || !user.company.logo_url) &&
                      <FaRegUser style={{margin: "0 auto"}} color="var(--primary)" fontSize="20px" />
                    }
                  </div>
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-primary">
                      <div className="menu-header-image opacity-3"
                        style={{
                          backgroundImage: `url(${background})`
                        }}/>
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left" style={{ maxWidth: '85%' }}>
                              <div className="widget-heading">
                                {user.company && <>{user.company.name}</>}
                              </div>
                            </div>
                            <div className="widget-content-right mr-2">
                              <Button id="logout" className="btn-pill btn-shadow btn-shine" color="focus" onClick={async () => {
                                setLoading(true);
                                try {
                                  await logout();
                                } catch (error) {
                                  ErrorToast({ placeholder: 'Ocorreu um erro interno ao tentar sair do sistema, tente novamente mais tarde' })
                                } finally {
                                  setLoading(false)
                                }
                              }}>
                                Sair
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="scroll-area-xs"
                    style={{
                      height: "auto",
                    }}>
                    <PerfectScrollbar id="scroll_user_menu">
                      <Nav onClick={handleToggleDropDown} vertical>
                        {
                          location.pathname !== "/bloqueado" &&
                          !user.is_contador &&
                          <NavItem>
                            <NavLink href="/#/perfil">
                              Meus Dados
                            </NavLink>
                          </NavItem>
                        }
                        {
                          handleCheckPlanPermission('users') &&
                          !user.is_contador &&
                          <NavItem>
                            <NavLink href="/#/usuarios">
                              Meus Usuários
                            </NavLink>
                          </NavItem>
                        }
                        {
                          handleCheckPlanPermission('company_profile') &&
                            <NavItem>
                              <NavLink href="/#/dados-empresa">
                                Dados da Empresa
                              </NavLink>
                            </NavItem>
                        }
                        {
                          handleCheckPlanPermission('company_settings') &&
                          <NavItem>
                            <NavLink href="/#/config-empresa">
                              Configurações da Empresa
                            </NavLink>
                          </NavItem>
                        }
                        {
                          handleCheckPlanPermission('company_settings') &&
                          <NavItem>
                            <NavLink href="/#/armazenamento">
                              Armazenamento
                            </NavLink>
                          </NavItem>
                        }
                        {
                          (user.permissions.includes('products') || user.permissions.includes('customers') || user.permissions.includes('sellers') || user.permissions.includes('providers') || user.permissions.includes('shipping_companies') || user.permissions.includes('installments_receive') || user.permissions.includes('installments_pay')) && location.pathname !== "/bloqueado" &&
                          <NavItem>
                            <NavLink href="/#/importa-dados">
                              Importar Dados
                            </NavLink>
                          </NavItem>
                        }
                        {
                          handleCheckPlanPermission('contador') &&
                          !user.is_contador &&
                          <NavItem>
                            <NavLink href="/#/meu-contador">
                              Meus Contadores
                            </NavLink>
                          </NavItem>
                        }
                        {
                          user.level === 1 && location.pathname !== "/bloqueado" &&
                            <>
                              <NavItem>
                                <NavLink href="/#/meu-plano">
                                  Meu Plano
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink href="/#/logs">
                                  Logs do Sistema
                                </NavLink>
                              </NavItem>
                            </>
                        }
                        </Nav>
                    </PerfectScrollbar>
                  </div>
                  {location.pathname !== "/bloqueado" && <><Nav vertical>
                    <NavItem className="nav-item-divider mb-0" />
                  </Nav>
                  <div className="grid-menu">
                    <Row onClick={handleToggleDropDown} className="no-gutters">
                    {
                        user.type !== 2 &&
                        <Col>
                          <Button id="btnSupport" className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                            outline color="alternate" onClick={() => history.push('/acesso-suporte')}>
                              <FaHeadset size="2rem" />
                              <Col md="12">
                                <b>Suporte</b>
                              </Col>
                          </Button>
                        </Col>
                      }
                      <Col>
                        <Button id="btnSuggestions" className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          outline color="info" onClick={() => history.push('/sugestoes')}>
                          <FaRegLightbulb size="2rem" />
                          <Col md="12">
                            <b>Sugestões</b>
                          </Col>
                        </Button>
                      </Col>
                      <Col>
                        <Button id="btnTickets" className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          outline color="success" onClick={() => history.push('/tickets')}>
                          <FaTicketAlt size="2rem" />
                          <Col md="12">
                            <b>Tickets</b>
                          </Col>
                        </Button>
                      </Col>
                    </Row>
                  </div></>}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UserBox;
